<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Paso 4 de 5
            </template>
            <template v-slot:subtitle>
                Carta Convenio
            </template>
            <template v-slot:content>
				<div class="p-grid p-justify-center">
					<h5><strong>CARTA - CONVENIO</strong></h5>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-2"></div>
						<div class="p-field p-col-9">
							<div class="p-text-justify">
								{{convenio.ini}}
							</div>
						</div>
						<div class="p-field p-col-2"></div>
						<div class="p-field p-col-9">
							<div class="p-fluid p-formgrid p-grid">
								<div v-for="(item, index) in convenio.cont" :key="index" class="p-field p-col-12">
									<div class="p-text-justify">
										<Checkbox inputId="binary" v-model="item.caso" :binary="true" @change="verificar"/>
										{{index+1}}) {{item.text}}
										<strong v-if="item.caso" style="color: red;">Leída y Aceptada</strong>
									</div>
								</div>
							</div>
							<small v-show="validationErrors.condiciones" style="color: red;">Debe leer todas las condiciones y marcar como leída.</small>
						</div>
						
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-2"></div>
						<div class="p-field p-col-10">
							Respecto a la Carta - Convenio antes descrita: 
						</div>
						<div class="p-field p-col-2"></div>
						<div class="p-field p-col-10">
							<Checkbox id="accept" name="accept" value="Accept" v-model="aceptar" :class="{'p-invalid':aceptar}" :disabled="condiciones==false"/>
							<strong>{{convenio.fin}}</strong>
						</div>
						<br>
						<div class="p-field p-col-2"></div>
						<div class="p-field p-col-10">
							<small v-show="validationErrors.conf" style="color: red;">Debe aceptar los términos para continuar</small>
						</div>

					</div>
                </div>

            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button label="Siguiente" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>

    </div>
</template>

<script>
import API from "@/service/API";
const Consulta = new API('AreaEstudiante','ProcesarEst');

export default {
	props: {
        formData: Object
    },
    data () {
        return {
            submitted: false,
            validationErrors: {},
			convenio: {
				ini: null,
				fin: null,
				cont: [],
			},
			aceptar: false,
			condiciones: false
        }
    },
	created() {
		Consulta.Procesar('Compromiso',{
			id: this.$store.state.matricula,
		}).then(response => {
			//this.$store.state.error = response;
			this.convenio = response.result;
			this.$store.commit('Loading');
		});
	},
    methods: {
		verificar(){

			var estado = true;
			this.convenio.cont.forEach(element => {
				if(!element.caso){
					estado = false;
				}
			});  
			this.condiciones = estado;
		},
		nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
            this.$emit('next-page', {pageIndex: 3});
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 3});
        },
        validateForm() {
            if (!this.condiciones)
                this.validationErrors['condiciones'] = true;
            else
                delete this.validationErrors['condiciones'];
            if (this.aceptar!='Accept')
                this.validationErrors['conf'] = true;
            else
                delete this.validationErrors['conf'];
            return !Object.keys(this.validationErrors).length;
        }

    }
}
</script>